import React from 'react'

import s from './MenuUsualItem.module.scss'

export type MenuUsualItem = {
  href: string
  text: string
}

export type MenuUsualItemProps = {
  item: MenuUsualItem
}

export const MenuUsualItem: React.FC<MenuUsualItemProps> = ({ item }) => {
  const { href, text } = item

  return (
    <li className={s.MenuUsualItem}>
      <a href={href}>{text}</a>
    </li>
  )
}
