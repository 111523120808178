import React from 'react'

export type ArrowIconProps = React.HtmlHTMLAttributes<SVGElement>

export const ArrowIcon: React.FC<ArrowIconProps> = ({ ...svgProps }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M2.75 11L19.25 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8335 4.58337L19.2502 11L12.8335 17.4167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
