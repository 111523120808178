import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

import clsx from 'clsx'

import Link from 'next/link'

import { Icon } from 'src/common/components/Icon/Icon'
import { ChevronIcon } from 'src/common/components/icons/ChevronIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { RoutesEnum } from 'src/common/enums/routesEnum'

import { TooltipMenu } from './TooltipMenu/TooltipMenu'

import s from './HeaderMenu.module.scss'

export type HeaderMenuProps = {
  className: string
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({ className }) => {
  const {
    setTriggerRef,
    setTooltipRef,
    visible: isTooltipVisible,
  } = usePopperTooltip({
    trigger: ['focus', 'hover'],
    delayHide: 360,
    interactive: true,
  })

  return (
    <nav className={className}>
      <ul className={s.HeaderMenu__inner}>
        <li className={clsx(s.HeaderMenu__item, s.HeaderMenu__item_button)}>
          <button ref={setTriggerRef} tabIndex={1}>
            Find developer
            <Icon
              icon={
                <ChevronIcon
                  className={clsx(s.HeaderMenu__icon, {
                    [s.HeaderMenu__icon_hovered]: isTooltipVisible,
                  })}
                />
              }
              boxSize={26}
            />
          </button>
        </li>

        <li className={s.HeaderMenu__item}>
          <Link href={RoutesEnum.ABOUT_PAGE}>
            <Typography variant={EnumTypographyVariants.H7} tabIndex={1} as="a">
              About us
            </Typography>
          </Link>
        </li>

        <li className={s.HeaderMenu__item}>
          <Link href={RoutesEnum.STORIES_PAGE}>
            <Typography variant={EnumTypographyVariants.H7} tabIndex={1} as="a">
              Reviews & Cases
            </Typography>
          </Link>
        </li>

        <li className={s.HeaderMenu__item}>
          <Link href={RoutesEnum.BLOG_PAGE}>
            <Typography variant={EnumTypographyVariants.H7} tabIndex={1} as="a">
              Blog
            </Typography>
          </Link>
        </li>
      </ul>

      <TooltipMenu isVisible={isTooltipVisible} setTooltipRef={setTooltipRef} />
    </nav>
  )
}
