import React from 'react'

import { ChevronIcon } from 'src/common/components/icons/ChevronIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { CURRENT_DATA_DOMAINS } from 'src/data/domainData/domains'
import { CURRENT_DATA_SKILLS } from 'src/data/skillsData/skills'

import { DomainCard } from '../../../_common/DomainCard/DomainCard'
import { SkillCard } from '../../../_common/SkillCard/SkillCard'
import {
  MobileMenuSubItem,
  MobileMenuSubItemEnum,
} from '../MobileMenuItems/MenuButtonItem/MenuButtonItem'

import s from './MenuSubItemList.module.scss'

export interface IMenuSubItemListProps {
  subItem: MobileMenuSubItem
  onReturnBack: () => void
}

export const MenuSubItemList: React.FC<IMenuSubItemListProps> = ({
  subItem,
  onReturnBack,
}) => {
  const renderCards = () => {
    if (subItem.type === MobileMenuSubItemEnum.FIND_DOMAIN) {
      return (
        <>
          {CURRENT_DATA_DOMAINS.map((domain, i) => (
            <DomainCard
              key={i}
              className={s.MenuSubItemList__card}
              {...domain}
            />
          ))}
        </>
      )
    }

    return (
      <>
        {CURRENT_DATA_SKILLS.map((skill, i) => (
          <SkillCard key={i} className={s.MenuSubItemList__card} {...skill} />
        ))}
      </>
    )
  }

  return (
    <div className={s.MenuSubItemList}>
      <Typography
        variant={EnumTypographyVariants.H4}
        className={s.MenuSubItemList__title}
        onClick={onReturnBack}
      >
        <div className={s.MenuSubItemList__titleIcon}>
          <ChevronIcon />
        </div>

        <div>{subItem.text}</div>
      </Typography>

      {renderCards()}
    </div>
  )
}
