import { DomainSkillEntity } from 'src/App/DomainOrSkillPage/DomainOrSkillPage'
import { isProd } from 'src/utils/config'

import chatGPTIcon from './_icons/chatGPTIcon.svg'
import cPlusPlus from './_icons/cPlusPlusIcon.svg'
import cSharpIcon from './_icons/cSharpIcon.svg'
import goIcon from './_icons/goIcon.svg'
import javaIcon from './_icons/javaIcon.svg'
import javaSpringIcon from './_icons/javaSpringIcon.svg'
import nodeIcon from './_icons/nodeIcon.svg'
import phpIcon from './_icons/phpIcon.svg'
import pythonDjangoIcon from './_icons/pythonDjangoIcon.svg'
import pythonIcon from './_icons/pythonIcon.svg'
import reactIcon from './_icons/reactIcon.svg'
import rubyIcon from './_icons/rubyIcon.svg'
import swiftIcon from './_icons/swiftIcon.svg'

export type SkillType = DomainSkillEntity

export const _BASE_DATA_SKILLS: Array<SkillType> = [
  {
    url: 'react',
    publicName: 'React',
    icon: reactIcon.src,
    developersCount: 116,
    clientReviewsCount: 83,
    rating: 4.7,
    type: 'skill',
    resumesIds: [
      '9ac53a4f-8b8b-46fa-afb4-21907f3e64e2',
      '75caf831-f40d-40c2-9215-9fa9ca1ad7c4',
      '340ae370-6068-4f6e-99a6-42b611c52eba',
      '740828bf-3133-4b81-9702-a10c0c1dc390',
      'e36b50ff-fc89-4bd5-94ee-fa5220c9a7e0',
      'afa398dd-3f72-427f-ac93-e4106ce6aca9',
    ],
  },
  {
    url: 'nodejs',
    publicName: 'Node',
    icon: nodeIcon.src,
    developersCount: 194,
    clientReviewsCount: 61,
    rating: 4.9,
    type: 'skill',
    resumesIds: [
      '400b9afd-80cc-4c3a-91cf-3134f115ac70',
      '7f51e722-77f3-423d-b664-5ec51de0c3da',
      '365c6d36-5daa-4dff-adbc-2eaa9f96be35',
      '84104635-8aa5-4a37-8496-752ba51a4a45',
      '36aa0a2c-5509-4265-b999-e701b204ad18',
      '2e499976-0cc9-453e-a2b5-74de92fde10f',
      '3ffdc233-167d-42b5-a188-f189930ad8e4',
    ],
  },
  {
    url: 'python',
    publicName: 'Python',
    icon: pythonIcon.src,
    developersCount: 104,
    clientReviewsCount: 98,
    rating: 4.8,
    type: 'skill',
    resumesIds: [
      '83848f6a-20cf-466d-ab4c-ea037d5218bc',
      '859bf92b-73ae-4d1c-86a3-c57c938dff23',
      '400b9afd-80cc-4c3a-91cf-3134f115ac70',
      '829f8968-587c-4d57-9e3a-040a2f97c2aa',
      'afa398dd-3f72-427f-ac93-e4106ce6aca9',
    ],
  },
  {
    url: 'python-django',
    publicName: 'Python Django',
    icon: pythonDjangoIcon.src,
    developersCount: 184,
    clientReviewsCount: 72,
    rating: 4.5,
    type: 'skill',
    resumesIds: [
      'e763ee8d-7907-4227-94c8-00c48ede8b0e',
      '62009618-ba59-48d3-824c-377718611cc1',
      'c722688d-d127-4db9-b1b8-6954f8b3193f',
      '67a5efbd-8e19-4144-81cd-6ee12937a85c',
      '8d7cf55d-f408-411d-b545-aaf4f3e35d48',
    ],
  },
  {
    url: 'java',
    publicName: 'Java',
    icon: javaIcon.src,
    developersCount: 134,
    clientReviewsCount: 66,
    rating: 4.7,
    type: 'skill',
    resumesIds: [
      'b275b515-fd20-4445-8e9e-1867a7a266dd',
      '058dd93b-5888-4bda-abde-a0c068795c0f',
      '9bf6c942-fb31-4f9a-ae70-c740598f3d94',
      '4130e575-43da-452c-bf62-4155caa40d80',
    ],
  },
  {
    url: 'conversational-ai',
    publicName: 'Conversational AI',
    icon: chatGPTIcon.src,
    developersCount: 87,
    clientReviewsCount: 32,
    rating: 4.9,
    type: 'skill',
    resumesIds: [
      '238517ab-d649-4546-b458-4dcfc44ce1dc',
      '859bf92b-73ae-4d1c-86a3-c57c938dff23',
      '62009618-ba59-48d3-824c-377718611cc1',
      '4207ca4d-263f-4ce4-ad3a-7e0b9049d4e5',
      '1cb794f3-2b5a-4a97-82b8-e94c2d2fb538',
      'e0ceb9c3-c6f1-4324-a84d-10ec36c65a17',
      '53bf45b3-696a-4fec-88eb-63cd45efa3b2',
    ],
  },
  {
    url: 'java-spring',
    publicName: 'Java Spring',
    icon: javaSpringIcon.src,
    developersCount: 134,
    clientReviewsCount: 66,
    rating: 4.7,
    type: 'skill',
    resumesIds: [
      '118440ce-0686-458b-857d-9422e411c704',
      'bbd6733e-d568-45d4-929e-8960cc8bba39',
      '3843b66d-ca93-4409-9f1a-28326b7d30d6',
      '9bf6c942-fb31-4f9a-ae70-c740598f3d94',
      '4130e575-43da-452c-bf62-4155caa40d80',
    ],
  },
  {
    url: 'php',
    publicName: 'PHP',
    icon: phpIcon.src,
    developersCount: 155,
    clientReviewsCount: 62,
    rating: 4.8,
    type: 'skill',
    resumesIds: [
      'afa398dd-3f72-427f-ac93-e4106ce6aca9',
      'ad3a0faf-a69b-4f08-b528-551297bef8f2',
      'd4c16724-8c40-47ac-988b-7f3ba9f2e234',
      '96814232-8896-4239-b38a-d3148f4815de',
      '851bed9b-a11e-4185-963c-e08cfd65bb0a',
    ],
  },
  {
    url: 'c-sharp',
    publicName: 'C#',
    icon: cSharpIcon.src,
    developersCount: 121,
    clientReviewsCount: 98,
    rating: 4.9,
    type: 'skill',
    resumesIds: [
      '43320009-10ac-4320-9dfa-9dbf384a8ed8',
      '6b4b2637-a116-4a0b-8218-896258f8552e',
      '058dd93b-5888-4bda-abde-a0c068795c0f',
      '7c1d5522-232f-4bc3-b8ad-485bf8ae8589',
      '80f2b163-026d-4221-a40c-b5fba42ff20b',
    ],
  },
  {
    url: 'c++',
    publicName: 'C++',
    icon: cPlusPlus.src,
    developersCount: 123,
    clientReviewsCount: 97,
    rating: 5,
    type: 'skill',
    resumesIds: [
      'd1ebdd4a-c630-438d-a415-29fe42ba282c',
      '40b247f8-489a-4a63-af38-71c759ce146f',
      '8511d0bb-5680-4232-9afd-c19be1fd081f',
      'aff3afee-2de1-4d53-9515-31f08f5c0b80',
    ],
  },
  {
    url: 'go',
    publicName: 'Golang',
    icon: goIcon.src,
    developersCount: 192,
    clientReviewsCount: 68,
    rating: 4.8,
    type: 'skill',
    resumesIds: [
      'bc4542ba-9349-4060-a5bb-740c2d486ba5',
      'bce52d77-82d2-4e67-87ba-1a1cdaacd77d',
      'f9055006-502c-4e29-b02f-866d2bfd70f0',
      '3eed097e-588c-4a61-b951-334658ec0d35',
    ],
  },
  {
    url: 'swift',
    publicName: 'Swift',
    icon: swiftIcon.src,
    developersCount: 165,
    clientReviewsCount: 90,
    rating: 4.9,
    type: 'skill',
    resumesIds: [
      'e7643a57-8b4f-47e0-88d7-6fa37b842fcf',
      '9a4d6c21-9a47-476a-9e64-18360e557cde',
      'a2d1398c-b10d-4def-99de-e1925a8ec4f2',
      '1a8bb6c9-f6fc-47ee-b8d1-528d64713dad',
    ],
  },
  {
    url: 'ruby',
    publicName: 'Ruby',
    icon: rubyIcon.src,
    developersCount: 162,
    clientReviewsCount: 57,
    rating: 4.9,
    type: 'skill',
    resumesIds: [
      'bfe0a871-a8ad-42bf-9dcb-0bce1a5dcfd5',
      'c7b2b232-7c11-4ae1-a0ba-2ba46b774f9e',
      '156c165a-ff67-4202-8cba-1dd291ce7afb',
      '440824cc-afe1-44c3-8965-9bf80b82c4d6',
    ],
  },
]

export const PROD_DATA_SKILLS = _BASE_DATA_SKILLS
export const QA_DATA_SKILLS = _BASE_DATA_SKILLS.map((skill) => ({
  ...skill,
  resumesIds: new Array(5).fill('bdfdbe12-0976-45ce-b7f0-979f70b338d8'),
}))
export const CURRENT_DATA_SKILLS = isProd() ? PROD_DATA_SKILLS : QA_DATA_SKILLS
