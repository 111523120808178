import React, { PropsWithChildren } from 'react'

import clsx from 'clsx'

import { Footer } from '../../components/Footer/Footer'
import { Header } from '../../components/Header/Header'

import s from './MainLayout.module.scss'

export type MainLayoutPropsClasses = {
  root?: string
  main?: string
}

export type MainLayoutProps = PropsWithChildren<{
  classes?: MainLayoutPropsClasses
  pageAnalytic: string
}>

export const MainLayout: React.FC<MainLayoutProps> = ({
  classes,
  children,
  pageAnalytic,
}) => {
  return (
    <div className={clsx(s.MainLayout, classes?.root)}>
      <Header pageAnalytic={pageAnalytic} />

      <main className={clsx(s.MainLayout__main, classes?.main)}>
        {children}
      </main>

      <Footer pageAnalytic={pageAnalytic} />
    </div>
  )
}
