import React from 'react'

import clsx from 'clsx'

import { FooterBottom } from './FooterBottom/FooterBottom'
import { FooterMiddle } from './FooterMiddle/FooterMiddle'
import { FooterTop } from './FooterTop/FooterTop'

import s from './Footer.module.scss'

export type FooterProps = {
  pageAnalytic: string
}

export const Footer: React.FC<FooterProps> = ({ pageAnalytic }) => {
  return (
    <footer className={s.Footer}>
      <div className={clsx(s.Footer__inner, 'container')}>
        <FooterTop pageAnalytic={pageAnalytic} />
        <FooterMiddle />
        <FooterBottom />
      </div>
    </footer>
  )
}
