import React from 'react'

import { Button } from '@insquad/tools'

import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import { Input } from '../../_form/Input/Input'
import { Logo } from '../../Logo/Logo'

import s from './FooterTop.module.scss'

export type FooterTopProps = {
  pageAnalytic: string
}

export const FooterTop: React.FC<FooterTopProps> = ({ pageAnalytic }) => {
  const { analytic } = useAnalytic()
  const { handleSubmitHireNow } = useAuthRedirect()

  const handleSignUp = (e: React.FormEvent<HTMLFormElement>) => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      page: pageAnalytic,
      from: 'input-footer',
    })

    handleSubmitHireNow(e)
  }

  return (
    <div className={s.FooterTop}>
      <Logo className={s.FooterTop__icon} />

      <form onSubmit={handleSignUp} className={s.FooterTop__form}>
        <Input
          containerProps={{ className: s.FooterTop__input }}
          placeholder="Enter your e-mail"
        />
        <Button id="button-footer-hirenow" className={s.FooterTop__btn}>
          Hire now
        </Button>
      </form>
    </div>
  )
}
