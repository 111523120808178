import React from 'react'

import clsx from 'clsx'

import Link from 'next/link'

import { RoutesEnum } from 'src/common/enums/routesEnum'

import { LogoIcon } from '../icons/LogoIcon'
import { LogoIconSmall } from '../icons/LogoIconSmall'

import s from './Logo.module.scss'

export type LogoProps = {
  className?: string
  isSmall?: boolean
}

export const Logo: React.FC<LogoProps> = ({ className, isSmall }) => {
  return (
    <Link href={RoutesEnum.HOME_PAGE}>
      <a href={RoutesEnum.HOME_PAGE} className={clsx(s.Logo, className)}>
        {isSmall ? <LogoIconSmall /> : <LogoIcon />}
      </a>
    </Link>
  )
}
