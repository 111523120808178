import { RoutesEnum } from 'src/common/enums/routesEnum'

import { MobileMenuSubItemEnum } from './MobileMenuItems/MenuButtonItem/MenuButtonItem'
import { MobileMenuItem } from './MobileMenuItems/MobileMenuItems'

export const MOBILE_MENU_ITEMS: Array<MobileMenuItem> = [
  {
    text: 'Find developer',
    subItems: [
      {
        text: 'Find developers in your domain',
        type: MobileMenuSubItemEnum.FIND_DOMAIN,
      },
      {
        text: 'Find developers by skill',
        type: MobileMenuSubItemEnum.FIND_SKILL,
      },
    ],
  },
  {
    href: RoutesEnum.ABOUT_PAGE,
    text: 'About us',
  },
  {
    href: RoutesEnum.STORIES_PAGE,
    text: 'Reviews & Cases',
  },
  {
    href: RoutesEnum.BLOG_PAGE,
    text: 'Blog',
  },
]
