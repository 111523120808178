import { useRef } from 'react'

import { APP_AMPLITUDE_KEY } from '../config'

import { Analytic } from './analytics'
import { IAnalytic } from './types'

let analytic: IAnalytic | null = null

export const useAnalytic = (): { analytic: IAnalytic } => {
  const getAnalyticInstance = (): IAnalytic => {
    if (analytic) return analytic

    analytic = new Analytic(APP_AMPLITUDE_KEY)
    return analytic
  }

  const classRef = useRef(getAnalyticInstance())

  return {
    analytic: classRef.current,
  }
}
