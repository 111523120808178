import React from 'react'

export function mergeRefs<T = HTMLElement>(
  refs: Array<
    | React.MutableRefObject<T>
    | React.LegacyRef<T>
    | React.ComponentProps<React.ElementType>['ref']
  >
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}
