import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const EdTechIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="60"
      height="32"
      viewBox="0 0 60 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22233 10.5L18.5 4.54794L31.7777 10.5L18.5 16.4521L5.22233 10.5Z"
        stroke="currentColor"
      />
      <path
        d="M9.5 22.7976V12.753L18.306 16.4608L18.5 16.5425L18.694 16.4608L27.5 12.753V22.7976C27.4446 22.849 27.3673 22.9159 27.2655 22.9943C26.9939 23.2032 26.545 23.495 25.8631 23.7915C24.4996 24.3843 22.1946 25 18.5 25C14.8054 25 12.5004 24.3843 11.1369 23.7915C10.455 23.495 10.0061 23.2032 9.73454 22.9943C9.63265 22.9159 9.55544 22.849 9.5 22.7976Z"
        stroke="currentColor"
      />
      <path d="M6.5 11V18.5" stroke="currentColor" />
      <rect x="5.5" y="18.5" width="2" height="2" stroke="currentColor" />
      <path
        d="M42.5 2.44077L43.977 6.43222C44.0783 6.70594 44.2941 6.92175 44.5678 7.02303L48.5592 8.5L44.5678 9.97697C44.2941 10.0783 44.0783 10.2941 43.977 10.5678L42.5 14.5592L41.023 10.5678C40.9217 10.2941 40.7059 10.0783 40.4322 9.97697L36.4408 8.5L40.4322 7.02303C40.7059 6.92175 40.9217 6.70594 41.023 6.43222L42.5 2.44077Z"
        stroke="currentColor"
      />
      <path
        d="M50.5 20.4408L51.4368 22.9724C51.5381 23.2461 51.7539 23.4619 52.0276 23.5632L54.5592 24.5L52.0276 25.4368C51.7539 25.5381 51.5381 25.7539 51.4368 26.0276L50.5 28.5592L49.5632 26.0276C49.4619 25.7539 49.2461 25.5381 48.9724 25.4368L46.4408 24.5L48.9724 23.5632C49.2461 23.4619 49.4619 23.2461 49.5632 22.9724L50.5 20.4408Z"
        stroke="currentColor"
      />
    </svg>
  )
}
