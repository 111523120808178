import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const AdTechIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="60"
      height="32"
      viewBox="0 0 60 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1638_3150" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8558 16.9319C21.2363 14.538 21.3375 11.4935 19.8564 8.9282C17.6473 5.10186 12.7545 3.79086 8.9282 6C5.10186 8.20914 3.79086 13.1019 6 16.9282C7.48105 19.4935 10.1683 20.9282 12.9317 20.9295C12.9417 20.95 12.9525 20.9703 12.9641 20.9904L14.4641 23.5885C14.7402 24.0667 15.3518 24.2306 15.8301 23.9545L21.0263 20.9545C21.5046 20.6783 21.6684 20.0667 21.3923 19.5885L19.8923 16.9904C19.8807 16.9703 19.8686 16.9508 19.8558 16.9319Z"
        />
      </mask>
      <path
        d="M19.8558 16.9319L18.9896 16.4323L18.6782 16.9722L19.0258 17.4895L19.8558 16.9319ZM12.9317 20.9295L13.8297 20.4895L13.5554 19.9298L12.9322 19.9295L12.9317 20.9295ZM12.9641 20.9904L13.8301 20.4904L12.9641 20.9904ZM14.4641 23.5885L13.5981 24.0885L14.4641 23.5885ZM18.9904 9.4282C20.2857 11.6717 20.199 14.3351 18.9896 16.4323L20.7221 17.4314C22.2737 14.7409 22.3892 11.3152 20.7224 8.4282L18.9904 9.4282ZM9.4282 6.86603C12.7763 4.93303 17.0574 6.08015 18.9904 9.4282L20.7224 8.4282C18.2372 4.12357 12.7328 2.64869 8.4282 5.13397L9.4282 6.86603ZM6.86603 16.4282C4.93303 13.0802 6.08015 8.79902 9.4282 6.86603L8.4282 5.13397C4.12357 7.61926 2.64869 13.1236 5.13397 17.4282L6.86603 16.4282ZM12.9322 19.9295C10.5112 19.9283 8.16131 18.6717 6.86603 16.4282L5.13397 17.4282C6.80078 20.3152 9.82535 21.928 12.9312 21.9295L12.9322 19.9295ZM13.8301 20.4904L13.8297 20.4895L12.0337 21.3696C12.0537 21.4103 12.0751 21.4506 12.0981 21.4904L13.8301 20.4904ZM15.3301 23.0885L13.8301 20.4904L12.0981 21.4904L13.5981 24.0885L15.3301 23.0885ZM15.3301 23.0885L13.5981 24.0885C14.1504 25.045 15.3735 25.3728 16.3301 24.8205L15.3301 23.0885ZM20.5263 20.0885L15.3301 23.0885L16.3301 24.8205L21.5263 21.8205L20.5263 20.0885ZM20.5263 20.0885L21.5263 21.8205C22.4829 21.2682 22.8106 20.045 22.2583 19.0885L20.5263 20.0885ZM19.0263 17.4904L20.5263 20.0885L22.2583 19.0885L20.7583 16.4904L19.0263 17.4904ZM19.0258 17.4895L19.0263 17.4904L20.7583 16.4904C20.7354 16.4506 20.7112 16.4119 20.6859 16.3742L19.0258 17.4895Z"
        fill="currentColor"
        mask="url(#path-1-inside-1_1638_3150)"
      />
      <path
        d="M16.8301 25.6865L22.0262 22.6865"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M19.6963 28.6504L23.1604 26.6504"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M17.8301 27.4189L23.0262 24.4189"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M29 8L44 3" stroke="currentColor" strokeLinecap="round" />
      <path d="M28 19.5L38 16" stroke="currentColor" strokeLinecap="round" />
      <path d="M24 15L55.5 3.5" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
