import { useCallback } from 'react'

import { AirtableUtils } from 'src/utils/AirtableUtils'
import {
  APP_SIGN_IN_CANDIDATE_REDIRECT_URL,
  APP_SIGN_IN_REDIRECT_URL,
  APP_SIGN_UP_REDIRECT_URL,
} from 'src/utils/config'

const _concatQuery = (variable: string, query?: string) => {
  // NOTICE needed for utm analytics
  const searchString = window.location.search
  const additionalQuery =
    searchString[0] === '?' ? `&${searchString.slice(1)}` : searchString

  return `${variable}${additionalQuery}${query ? `&${query}` : ''}`
}

export const useAuthRedirect = () => {
  const handleSigInRedirect = useCallback((additionalQuery?: string) => {
    window.open(
      _concatQuery(APP_SIGN_IN_REDIRECT_URL, additionalQuery),
      '_self'
    )
  }, [])

  const handleSignUpRedirect = useCallback((additionalQuery?: string) => {
    window.open(
      _concatQuery(APP_SIGN_UP_REDIRECT_URL, additionalQuery),
      '_self'
    )
  }, [])

  const handleSignInCandidateRedirect = useCallback(
    (additionalQuery?: string) => {
      window.open(
        _concatQuery(APP_SIGN_IN_CANDIDATE_REDIRECT_URL, additionalQuery),
        '_self'
      )
    },
    []
  )

  const handleSubmitHireNow = useCallback(
    (formEvent: React.FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault()
      const formEmail = (
        (formEvent.target as HTMLFormElement)[0] as HTMLInputElement
      ).value?.trim()

      AirtableUtils.makeOperation(({ companyBase }) => {
        companyBase
          .table('LandingEmails')
          .create({
            Email: formEmail,
          })
          .finally(() => {
            handleSignUpRedirect(`email=${formEmail}`)
          })
      })
    },
    [handleSignUpRedirect]
  )

  return {
    handleSigInRedirect,
    handleSignUpRedirect,
    handleSignInCandidateRedirect,
    handleSubmitHireNow,
  }
}
