import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const MetaverseIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="60"
      height="32"
      viewBox="0 0 60 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1638_3053)">
        <mask id="path-1-inside-1_1638_3053" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.39548 20.6366C11.3621 21.5117 13.5163 21.4068 15.3108 20.5324L20.131 22.6771C20.6828 24.5956 22.0471 26.266 24.0137 27.1411C27.5458 28.7127 31.6832 27.1234 33.2549 23.5913C34.8265 20.0592 33.2372 15.9218 29.7051 14.3501L15.0869 7.8457C11.5548 6.27406 7.41734 7.86335 5.84571 11.3955C4.27407 14.9276 5.86336 19.065 9.39548 20.6366Z"
          />
        </mask>
        <path
          d="M15.3108 20.5324L15.7174 19.6187L15.2917 19.4293L14.8728 19.6334L15.3108 20.5324ZM20.131 22.6771L21.092 22.4007L20.9632 21.9529L20.5375 21.7635L20.131 22.6771ZM14.8728 19.6334C13.3324 20.384 11.4874 20.4729 9.80201 19.723L8.98895 21.5503C11.2368 22.5504 13.7002 22.4297 15.7489 21.4313L14.8728 19.6334ZM20.5375 21.7635L15.7174 19.6187L14.9043 21.446L19.7245 23.5908L20.5375 21.7635ZM24.4202 26.2274C22.7349 25.4775 21.5657 24.0475 21.092 22.4007L19.1699 22.9536C19.7999 25.1438 21.3594 27.0546 23.6072 28.0547L24.4202 26.2274ZM32.3412 23.1848C30.9941 26.2123 27.4478 27.5746 24.4202 26.2274L23.6072 28.0547C27.6439 29.8509 32.3723 28.0345 34.1685 23.9978L32.3412 23.1848ZM29.2986 15.2638C32.3261 16.6109 33.6883 20.1573 32.3412 23.1848L34.1685 23.9978C35.9646 19.9611 34.1483 15.2327 30.1116 13.4365L29.2986 15.2638ZM14.6803 8.75934L29.2986 15.2638L30.1116 13.4365L15.4934 6.93206L14.6803 8.75934ZM6.75935 11.802C8.10646 8.77446 11.6528 7.41222 14.6803 8.75934L15.4934 6.93206C11.4567 5.1359 6.72823 6.95223 4.93207 10.9889L6.75935 11.802ZM9.80201 19.723C6.77448 18.3759 5.41223 14.8295 6.75935 11.802L4.93207 10.9889C3.13592 15.0256 4.95224 19.7541 8.98895 21.5503L9.80201 19.723Z"
          fill="currentColor"
          mask="url(#path-1-inside-1_1638_3053)"
        />
        <circle
          cx="28.0789"
          cy="18.0047"
          r="1"
          transform="rotate(23.9869 28.0789 18.0047)"
          fill="currentColor"
        />
        <circle
          cx="25.6399"
          cy="23.4862"
          r="1"
          transform="rotate(23.9869 25.6399 23.4862)"
          fill="currentColor"
        />
        <circle
          cx="24.1184"
          cy="19.5262"
          r="1"
          transform="rotate(23.9869 24.1184 19.5262)"
          fill="currentColor"
        />
        <circle
          cx="29.6004"
          cy="21.9647"
          r="1"
          transform="rotate(23.9869 29.6004 21.9647)"
          fill="currentColor"
        />
        <path
          d="M13.4609 11.5L11.0218 16.9818"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 13.0215L14.9818 15.4607"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5 11.5C22.5 11.5 26.6998 4.50729 31 4.5C36.2757 4.49106 33.5 12 39.5 15C45.5 18 47.0718 10.796 52 11.5C56.4079 12.1297 60 16.5 60 16.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1638_3053">
          <rect width="60" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
