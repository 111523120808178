import React from 'react'

import clsx from 'clsx'

import s from './ContentSection.module.scss'

export type ContentSectionProps = {
  children: React.ReactNode

  isPaddingIndent?: boolean
} & React.HtmlHTMLAttributes<HTMLDivElement>

export const ContentSection: React.FC<ContentSectionProps> = ({
  children,
  className,
  isPaddingIndent,
  ...sectionProps
}) => {
  return (
    <section
      {...sectionProps}
      className={clsx(
        {
          [s.ContentSection_margin]: !isPaddingIndent,
          [s.ContentSection_padding]: isPaddingIndent,
        },
        className
      )}
    >
      {children}
    </section>
  )
}
