import React, { useEffect, useState } from 'react'

import { useDisableOverflow } from '@insquad/tools'

import {
  EnumTextButtonVariants,
  TextButton,
} from 'src/common/components/TextButton'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'

import { MenuSubItemList } from './MenuSubItemList/MenuSubItemList'
import { MobileMenuBlackout } from './MobileMenuBlackout/MobileMenuBlackout'
import { MobileMenuDrawer } from './MobileMenuDrawer/MobileMenuDrawer'
import { MOBILE_MENU_ITEMS } from './mobileMenuItems'
import { MobileMenuSubItem } from './MobileMenuItems/MenuButtonItem/MenuButtonItem'
import { MobileMenuItems } from './MobileMenuItems/MobileMenuItems'

import s from './MobileMenu.module.scss'

export type MobileMenuProps = {
  isVisible: boolean
  onClose: () => void
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  isVisible,
  onClose,
}) => {
  const { handleSigInRedirect } = useAuthRedirect()

  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  )
  const [chosenSubItem, setChosenSubItem] = useState<MobileMenuSubItem | null>(
    null
  )

  const resetState = () => {
    setSelectedItemIndex(null)
    setChosenSubItem(null)
  }

  const renderContent = () => {
    if (chosenSubItem === null) {
      return (
        <MobileMenuItems
          items={MOBILE_MENU_ITEMS}
          selectedItemIndex={selectedItemIndex}
          onSelectedItem={setSelectedItemIndex}
          onSubItem={setChosenSubItem}
        />
      )
    }

    return <MenuSubItemList onReturnBack={resetState} subItem={chosenSubItem} />
  }

  useDisableOverflow(isVisible)

  useEffect(() => {
    if (!isVisible) {
      resetState()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <>
      <MobileMenuBlackout isVisible={isVisible} onClick={onClose} />
      <MobileMenuDrawer isVisible={isVisible}>
        {renderContent()}
        <TextButton
          onClick={() => handleSigInRedirect()}
          variant={EnumTextButtonVariants.SECONDARY}
          className={s.MobileMenu__login}
        >
          Log In
        </TextButton>
      </MobileMenuDrawer>
    </>
  )
}
