import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import { FooterMiddleContacts } from './FooterMiddleContacts/FooterMiddleContacts'
import { FooterMiddleList } from './FooterMiddleList/FooterMiddleList'
import { FOOTER_MIDDLE_LISTS } from './footerMiddleLists'

import s from './FooterMiddle.module.scss'

export const FooterMiddle: React.FC<UnknownObject> = () => {
  return (
    <div className={s.FooterMiddle}>
      <div className={s.FooterMiddle__lists}>
        {FOOTER_MIDDLE_LISTS.map((list, i) => (
          <FooterMiddleList
            key={i}
            className={s.FooterMiddle__list}
            {...list}
          />
        ))}
      </div>

      <FooterMiddleContacts />
    </div>
  )
}
