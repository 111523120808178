import { config, useSprings, UseSpringsProps } from 'react-spring'

export type UseCardsHopAnimationProps<T> = {
  cards: Array<T>
  delayMultiplier?: number
  isPaused?: boolean
} & UseSpringsProps

export const useCardsHopAnimation = <T,>({
  delayMultiplier = 180,
  isPaused,
  cards,
  ...otherProps
}: UseCardsHopAnimationProps<T>) => {
  const springs = useSprings(
    cards.length,
    cards.map((_, i) => ({
      pause: isPaused,
      delay: i > 6 ? delayMultiplier * 6 : delayMultiplier * i,
      opacity: 1,
      transform: 'translateY(0px)',
      from: {
        opacity: 0,
        transform: 'translateY(100px)',
      },
      config: config.stiff,
      ...otherProps,
    }))
  )

  return springs
}
