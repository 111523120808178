import React from 'react'

import clsx from 'clsx'

import Image from 'next/image'
import Link from 'next/link'

import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { SkillType } from 'src/data/skillsData/skills'

import s from './SkillCard.module.scss'

export type SkillTypeProps = {
  className?: string
} & SkillType

export const SkillCard: React.FC<SkillTypeProps> = ({
  icon,
  url,
  publicName,
  className,
}) => {
  return (
    <Link href={`/${url}`}>
      <a href={url} className={clsx(s.SkillCard, className)}>
        <Typography
          variant={EnumTypographyVariants.H7}
          className={s.SkillCard__title}
        >
          <div className={s.SkillCard__icon}>
            <Image
              src={icon}
              layout="fixed"
              alt={publicName}
              width={32}
              height={32}
            />
          </div>

          {publicName}
        </Typography>

        <div className={s.SkillCard__arrow}>
          <ArrowIcon />
        </div>
      </a>
    </Link>
  )
}
