import React from 'react'

import clsx from 'clsx'

import s from './Typography.module.scss'

export enum EnumTypographyVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  H7 = 'h7',
  H8 = 'h8',
  H9 = 'h9',
  P1F = 'p1-f',
  P2F = 'p2-f',
  P3F = 'p3-f',
  H1F = 'h1-f',
  H2F = 'h2-f',
  H3F = 'h3-f',
  H4F = 'h4-f',
  CAPTION = 'caption',
}

export const getDefaultTagFromVariant = (
  variant: EnumTypographyVariants
): React.ElementType => {
  if (
    variant === EnumTypographyVariants.P1F ||
    variant === EnumTypographyVariants.P2F ||
    variant === EnumTypographyVariants.P3F
  ) {
    return 'p'
  }

  if (
    variant === EnumTypographyVariants.H7 ||
    variant === EnumTypographyVariants.H8 ||
    variant === EnumTypographyVariants.H9 ||
    variant === EnumTypographyVariants.CAPTION
  ) {
    return 'div'
  }

  if (variant === EnumTypographyVariants.H1F) {
    return 'h1'
  }

  if (variant === EnumTypographyVariants.H2F) {
    return 'h2'
  }

  if (variant === EnumTypographyVariants.H3F) {
    return 'h3'
  }

  if (variant === EnumTypographyVariants.H4F) {
    return 'h4'
  }

  return variant
}

type TypographyOwnProps<E extends React.ElementType> = {
  variant?: EnumTypographyVariants
  as?: E
  className?: string
  text?: string | number
}

export type ITypographyProps<E extends React.ElementType> =
  TypographyOwnProps<E> &
    Omit<React.ComponentProps<E>, keyof TypographyOwnProps<E>>

const TypographyInner = <E extends React.ElementType = 'div'>(
  {
    as,
    className,
    children,
    text,
    variant = EnumTypographyVariants.H1,
    ...otherProps
  }: ITypographyProps<E>,
  ref: React.ForwardedRef<E>
) => {
  const TagToRender = as ?? getDefaultTagFromVariant(variant)

  return (
    <TagToRender
      {...otherProps}
      ref={ref}
      className={clsx(s.Typography, s[`Typography_${variant}`], className)}
    >
      {children ?? text}
    </TagToRender>
  )
}

export const Typography = React.forwardRef(TypographyInner) as <
  E extends React.ElementType = 'div'
>(
  props: ITypographyProps<E> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof TypographyInner>
