import React from 'react'

import clsx from 'clsx'

import Link from 'next/link'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './FooterMiddleList.module.scss'

type FooterMiddleListLink =
  | {
      href: string
      text: string
      anchorProps?:
        | Omit<React.HtmlHTMLAttributes<HTMLAnchorElement>, 'href'> & {
            target?: '_blank'
            rel?: string
          }
    }
  | {
      internalLink: string
      text: string
    }

export type FooterMiddleListProps = {
  title: string
  links: Array<FooterMiddleListLink>
  className: string
}

export const FooterMiddleList: React.FC<FooterMiddleListProps> = ({
  title,
  links,
  className,
}) => {
  const renderLink = (link: FooterMiddleListLink, index: number) => {
    const content =
      'internalLink' in link ? (
        <Link href={link.internalLink}>
          <a href={link.internalLink}>{link.text}</a>
        </Link>
      ) : (
        <a href={link.href} {...link.anchorProps}>
          {link.text}
        </a>
      )

    return (
      <li key={index} className={s.FooterMiddleList__listItem}>
        {content}
      </li>
    )
  }

  return (
    <div className={clsx(s.FooterMiddleList, className)}>
      <Typography
        variant={EnumTypographyVariants.H9}
        text={title}
        className={s.FooterMiddleList__title}
      />

      <ul className={s.FooterMiddleList__list}>{links.map(renderLink)}</ul>
    </div>
  )
}
