import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const LogoIconSmall: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="65"
      height="20"
      viewBox="0 0 65 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.90413 2.85305V14.8213H0V2.85305H2.90413Z" fill="#1B1B1B" />
      <path
        d="M8.10442 14.8213H5.27672V5.70263H7.47392L7.79872 6.84247C8.20632 6.42453 8.66487 6.09524 9.17436 5.85461C9.68386 5.61398 10.2379 5.49366 10.8366 5.49366C11.8683 5.49366 12.658 5.77229 13.2057 6.32954C13.7535 6.88679 14.0273 7.70367 14.0273 8.78018V14.8213H11.1996V9.06514C11.1996 8.62187 11.0913 8.27359 10.8748 8.02029C10.671 7.75433 10.3653 7.62135 9.95771 7.62135C9.65201 7.62135 9.32721 7.71001 8.9833 7.88731C8.65213 8.06462 8.35917 8.29892 8.10442 8.59021V14.8213Z"
        fill="#1B1B1B"
      />
      <path
        d="M21.9032 8.03929C21.5593 7.87465 21.1963 7.748 20.8142 7.65935C20.432 7.55803 20.0627 7.50737 19.706 7.50737C19.3748 7.50737 19.1073 7.56436 18.9035 7.67834C18.6997 7.79233 18.5978 7.95064 18.5978 8.15327C18.5978 8.36858 18.6934 8.55222 18.8844 8.70419C19.0882 8.84351 19.4831 8.99548 20.069 9.16013L20.6422 9.3311C21.4064 9.55907 21.986 9.89469 22.3809 10.338C22.7757 10.7686 22.9731 11.3258 22.9731 12.0097C22.9731 12.4656 22.8776 12.8836 22.6866 13.2635C22.4955 13.6308 22.2216 13.9474 21.865 14.2134C21.5211 14.4794 21.1007 14.682 20.604 14.8213C20.12 14.9606 19.5786 15.0303 18.98 15.0303C18.3304 15.0303 17.7508 14.9733 17.2413 14.8593C16.7446 14.7453 16.2796 14.5807 15.8466 14.3654L16.458 12.3327C16.8274 12.5226 17.2095 12.6809 17.6043 12.8076C18.0119 12.9216 18.4259 12.9786 18.8462 12.9786C19.3302 12.9786 19.6678 12.9089 19.8589 12.7696C20.0627 12.6303 20.1645 12.4656 20.1645 12.2757C20.1645 12.1617 20.1391 12.0667 20.0881 11.9907C20.0499 11.9021 19.9735 11.8197 19.8589 11.7438C19.7442 11.6678 19.5786 11.5981 19.3621 11.5348C19.1583 11.4588 18.8908 11.3701 18.5596 11.2688L17.9673 11.0978C17.2158 10.8572 16.6681 10.5279 16.3242 10.11C15.9803 9.67938 15.8084 9.10314 15.8084 8.38124C15.8084 7.9633 15.8912 7.58336 16.0567 7.24141C16.2223 6.88679 16.458 6.58284 16.7637 6.32954C17.0821 6.06358 17.4642 5.86094 17.91 5.72163C18.3686 5.56965 18.8908 5.49366 19.4767 5.49366C20.0627 5.49366 20.604 5.55065 21.1007 5.66464C21.6102 5.76595 22.0688 5.9116 22.4764 6.10157L21.9032 8.03929Z"
        fill="#1B1B1B"
      />
      <path
        d="M30.5838 14.4224C30.3545 14.6123 30.0425 14.758 29.6476 14.8593C29.2527 14.9733 28.8706 15.0303 28.5012 15.0303C27.8261 15.0303 27.2275 14.9163 26.7053 14.6883C26.183 14.4477 25.7372 14.1184 25.3678 13.7005C25.0112 13.2825 24.7373 12.7823 24.5463 12.1997C24.3552 11.6171 24.2597 10.9712 24.2597 10.262C24.2597 9.57807 24.3552 8.94483 24.5463 8.36224C24.7373 7.77966 25.0175 7.2794 25.3869 6.86146C25.7563 6.43086 26.2021 6.09524 26.7244 5.85461C27.2593 5.61398 27.8644 5.49366 28.5394 5.49366C29.0107 5.49366 29.4756 5.57598 29.9342 5.74063C30.3927 5.8926 30.7812 6.1269 31.0997 6.44352L31.2525 5.70263H33.4115V19.1907H30.5838V14.4224ZM30.5838 8.26726C30.4437 8.08995 30.2463 7.92531 29.9915 7.77333C29.7495 7.60869 29.4438 7.52637 29.0744 7.52637C28.8069 7.52637 28.5522 7.57702 28.3102 7.67834C28.0809 7.77966 27.8707 7.93797 27.6797 8.15327C27.5013 8.36858 27.3612 8.6472 27.2593 8.98915C27.1574 9.31844 27.1065 9.72371 27.1065 10.205C27.1065 11.1295 27.3039 11.8261 27.6988 12.2947C28.1064 12.7506 28.5904 12.9786 29.1508 12.9786C29.482 12.9786 29.7686 12.9216 30.0106 12.8076C30.2526 12.6809 30.4437 12.5353 30.5838 12.3707V8.26726Z"
        fill="#1B1B1B"
      />
      <path
        d="M41.4984 5.70263H44.3262V14.8213H42.2436L41.957 13.8334H41.8806C41.7532 13.9601 41.6003 14.0931 41.422 14.2324C41.2437 14.3717 41.0272 14.5047 40.7724 14.6313C40.5304 14.7453 40.2502 14.8403 39.9318 14.9163C39.6133 14.9923 39.2503 15.0303 38.8427 15.0303C37.8492 15.0303 37.0722 14.758 36.5118 14.2134C35.9513 13.6688 35.6711 12.8646 35.6711 11.8007V5.70263H38.4988V11.5728C38.4988 12.0414 38.6134 12.3897 38.8427 12.6176C39.0847 12.8329 39.3904 12.9406 39.7598 12.9406C40.2056 12.9406 40.5686 12.8519 40.8488 12.6746C41.1418 12.4846 41.3583 12.2883 41.4984 12.0857V5.70263Z"
        fill="#1B1B1B"
      />
      <path
        d="M46.7208 6.50051C47.2431 6.19656 47.829 5.95593 48.4786 5.77862C49.1409 5.58865 49.816 5.49366 50.5038 5.49366C51.6375 5.49366 52.5482 5.76595 53.236 6.31054C53.9238 6.85513 54.2677 7.72267 54.2677 8.91316V14.8213H52.1088L51.8604 13.9094H51.784C51.5292 14.2387 51.1726 14.511 50.714 14.7263C50.2682 14.929 49.7396 15.0303 49.1282 15.0303C48.606 15.0303 48.1474 14.9543 47.7526 14.8023C47.3704 14.6503 47.052 14.4477 46.7973 14.1944C46.5425 13.9411 46.3451 13.6435 46.205 13.3015C46.0776 12.9469 46.0139 12.5733 46.0139 12.1807C46.0139 11.6488 46.1285 11.1928 46.3578 10.8129C46.5998 10.4329 46.9183 10.1227 47.3131 9.88202C47.7207 9.64139 48.192 9.46408 48.727 9.3501C49.2747 9.23612 49.8542 9.17912 50.4656 9.17912H51.4209V8.6852C51.4209 8.34325 51.2999 8.08362 51.0579 7.90631C50.8159 7.71634 50.472 7.62135 50.0262 7.62135C49.6441 7.62135 49.2683 7.67834 48.8989 7.79233C48.5295 7.90631 48.122 8.08995 47.6761 8.34325L46.7208 6.50051ZM51.44 10.8889L50.8095 10.9079C50.4529 10.9205 50.1536 10.9585 49.9116 11.0219C49.6695 11.0852 49.4721 11.1675 49.3193 11.2688C49.1792 11.3575 49.0773 11.4715 49.0136 11.6108C48.9499 11.7374 48.918 11.8704 48.918 12.0097C48.918 12.4023 49.0327 12.6746 49.2619 12.8266C49.504 12.9659 49.7778 13.0356 50.0835 13.0356C50.3892 13.0356 50.6631 12.9469 50.9051 12.7696C51.1598 12.5923 51.3381 12.396 51.44 12.1807V10.8889Z"
        fill="#1B1B1B"
      />
      <path
        d="M62.1723 1.23828H65V14.8213H63.0512L62.7646 13.8334H62.6691C62.4907 14.1121 62.1787 14.3844 61.7329 14.6503C61.2998 14.9036 60.7266 15.0303 60.0133 15.0303C59.4146 15.0303 58.8606 14.9226 58.3511 14.7073C57.8416 14.4794 57.4021 14.1627 57.0328 13.7575C56.6634 13.3395 56.3704 12.8393 56.1539 12.2567C55.9501 11.6614 55.8482 10.9965 55.8482 10.262C55.8482 9.54007 55.9437 8.88783 56.1348 8.30525C56.3258 7.71 56.5997 7.20974 56.9563 6.80447C57.3257 6.38653 57.7779 6.06358 58.3129 5.83561C58.8478 5.60764 59.4529 5.49366 60.1279 5.49366C60.5483 5.49366 60.9431 5.55699 61.3125 5.68363C61.6946 5.79762 61.9812 5.94326 62.1723 6.12057V1.23828ZM62.1723 8.17227C62.0577 8.02029 61.8793 7.87465 61.6373 7.73533C61.3953 7.59602 61.1278 7.52637 60.8349 7.52637C60.5419 7.52637 60.2681 7.57702 60.0133 7.67834C59.7586 7.767 59.5293 7.91897 59.3255 8.13428C59.1344 8.34958 58.9816 8.6282 58.8669 8.97015C58.7523 9.29944 58.695 9.71105 58.695 10.205C58.695 11.1548 58.8797 11.8577 59.2491 12.3137C59.6312 12.7569 60.1216 12.9786 60.7202 12.9786C61.0896 12.9786 61.3953 12.8836 61.6373 12.6936C61.8921 12.5036 62.0704 12.301 62.1723 12.0857V8.17227Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90413 2.66211V15.0103H0V2.66211H2.90413ZM8.10442 15.0103H5.27672V5.60216H7.47392L7.79872 6.77818C8.20632 6.34697 8.66487 6.00723 9.17436 5.75896C9.68386 5.51069 10.2379 5.38655 10.8366 5.38655C11.8683 5.38655 12.658 5.67402 13.2058 6.24897C13.7535 6.82391 14.0273 7.66672 14.0273 8.77741V15.0103H11.1996V9.07141C11.1996 8.61407 11.0913 8.25473 10.8748 7.9934C10.671 7.71899 10.3653 7.58179 9.95771 7.58179C9.65202 7.58179 9.32721 7.67326 8.9833 7.85619C8.65213 8.03913 8.35917 8.28087 8.10442 8.58141V15.0103Z"
        fill="#373AF5"
      />
    </svg>
  )
}
