import React from 'react'

import clsx from 'clsx'

import { useToggle } from '@insquad/tools'

import { BurgerMenu } from '../../BurgerMenu/BurgerMenu'
import { Logo } from '../../Logo/Logo'

import { MobileMenu } from './MobileMenu/MobileMenu'

import s from './HeaderMobileMenu.module.scss'

export interface IHeaderMobileMenuProps {
  className: string
}

export const HeaderMobileMenu: React.FC<IHeaderMobileMenuProps> = ({
  className,
}) => {
  const menuToggler = useToggle()

  return (
    <div className={clsx(s.HeaderMobileMenu, className)}>
      <BurgerMenu isOpen={menuToggler.value} onClick={menuToggler.toggle} />
      <Logo className={s.HeaderMobileMenu__logo} />

      <MobileMenu isVisible={menuToggler.value} onClose={menuToggler.unset} />
    </div>
  )
}
