import React from 'react'

import clsx from 'clsx'

import s from './Icon.module.scss'

export type IconProps = {
  icon: React.ReactNode
  boxSize?: number
  className?: string

  onClick?: () => void
}

export const Icon: React.FC<IconProps> = ({
  icon,
  className,
  boxSize,
  onClick,
}) => {
  const boxStyle = boxSize
    ? {
        minWidth: `${boxSize}px`,
        width: `${boxSize}px`,
        height: `${boxSize}px`,
      }
    : undefined

  const IconCmp = !!onClick ? 'button' : 'div'

  return (
    <IconCmp
      className={clsx(s.Icon, className)}
      style={{ ...boxStyle, cursor: !!onClick ? 'pointer' : 'unset' }}
      onClick={onClick}
    >
      {icon}
    </IconCmp>
  )
}
