import React from 'react'

import { CardsHopAnimation } from 'src/common/animations/CardsHopAnimation/CardsHopAnimation'
import { DomainType } from 'src/data/domainData/domains'

import { DomainCard } from '../../../_common/DomainCard/DomainCard'

import s from './TooltipMenuDomains.module.scss'

export type TooltipMenuDomainsProps = {
  domains: Array<DomainType>
}

export const TooltipMenuDomains: React.FC<TooltipMenuDomainsProps> = ({
  domains,
}) => {
  return (
    <div className={s.TooltipMenuDomains}>
      <CardsHopAnimation
        cards={domains}
        renderCard={(domain) => <DomainCard {...domain} />}
        animationConfig={{ delayMultiplier: 100 }}
      />
    </div>
  )
}
