import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const LinkedInSocialIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2894_42118)">
        <path
          d="M12 0C5.37254 0 0 5.3725 0 11.9999C0 18.6273 5.37254 23.9998 12 23.9998C18.6275 23.9998 24.0001 18.6273 24.0001 11.9999C24 5.3725 18.6274 0 12 0ZM8.79838 17.8013H6.16366V9.28935H8.79838V17.8013ZM7.46846 8.17464C6.60799 8.17464 5.91043 7.4714 5.91043 6.60404C5.91043 5.73658 6.60809 5.03339 7.46846 5.03339C8.32884 5.03339 9.0264 5.73658 9.0264 6.60404C9.02645 7.47145 8.32889 8.17464 7.46846 8.17464ZM18.672 17.8013H16.0501V13.3332C16.0501 12.1078 15.5846 11.4237 14.6155 11.4237C13.5609 11.4237 13.01 12.1362 13.01 13.3332V17.8013H10.483V9.28935H13.01V10.4358C13.01 10.4358 13.7701 9.02971 15.575 9.02971C17.38 9.02971 18.672 10.1318 18.672 12.4117L18.672 17.8013Z"
          fill="url(#paint0_linear_2894_42118)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2894_42118"
          x1="3.51472"
          y1="3.51469"
          x2="20.4852"
          y2="20.4853"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2489BE" />
          <stop offset="1" stopColor="#0575B3" />
        </linearGradient>
        <clipPath id="clip0_2894_42118">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
