export enum AnalyticEventEnum {
  LANDING_PAGE = 'Landing Page',
  DOMAIN_PAGE = 'Domain Page',
  SKILL_PAGE = 'Skill Page',
  ABOUT_PAGE = 'About Page',
  CASES_PAGE = 'Cases Page',
  CASE_PAGE = 'Case Page',
  BLOG_PAGE = 'Blog Page',
  ARTICLE_PAGE = 'Article Page',
  SIGN_UP = 'sign up click',
}
