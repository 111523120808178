import { DomainSkillEntity } from 'src/App/DomainOrSkillPage/DomainOrSkillPage'
import { isProd } from 'src/utils/config'

import { AdTechIcon } from './_iconCmps/AdTechIcon'
import { AIIcon } from './_iconCmps/AIIcon'
import { EdTechIcon } from './_iconCmps/EdTechIcon'
import { FinTechIcon } from './_iconCmps/FinTechIcon'
import { MetaverseIcon } from './_iconCmps/MetaverseIcon'
import { Web3Icon } from './_iconCmps/Web3Icon'
import AdTechSvgIcon from './_icons/AdTechIcon.svg'
import AISvgIcon from './_icons/AIIcon.svg'
import EdTechSvgIcon from './_icons/EdTechIcon.svg'
import FinTechSvgIcon from './_icons/FinTechIcon.svg'
import MetaverseSvgIcon from './_icons/MetaverseIcon.svg'
import Web3SvgIcon from './_icons/Web3Icon.svg'

export type DomainType = DomainSkillEntity & {
  iconCmp: React.ReactNode
}

const _BASE_DATA_DOMAINS: Array<DomainType> = [
  {
    publicName: 'Fintech',
    url: 'fintech',
    icon: FinTechSvgIcon.src,
    iconCmp: <FinTechIcon />,
    developersCount: 144,
    clientReviewsCount: 76,
    rating: 4.8,
    type: 'domain',
    resumesIds: [
      '8e6e71ff-d6e6-4d5a-b1db-4619b93a8935',
      '4fc2b836-0a47-432a-acc4-a6e696f92cc9',
      '649489b3-0381-48d6-9ba6-e922950e46a2',
      '118440ce-0686-458b-857d-9422e411c704',
      'b275b515-fd20-4445-8e9e-1867a7a266dd',
    ],
  },
  {
    publicName: 'Web3/Blockchain',
    url: 'web3-blockchain',
    icon: Web3SvgIcon.src,
    iconCmp: <Web3Icon />,
    developersCount: 102,
    clientReviewsCount: 97,
    rating: 4.7,
    type: 'domain',
    resumesIds: [
      'e570666c-9813-4a3c-9ab6-f4b617a7e05d',
      'fb0ae9e4-cd62-4124-8517-b4895b8be773',
      'bce52d77-82d2-4e67-87ba-1a1cdaacd77d',
      '7f51e722-77f3-423d-b664-5ec51de0c3da',
      '15b97add-cd48-4eac-9719-17a50e93f094',
    ],
  },
  {
    publicName: 'Metaverse/Gamedev',
    url: 'metaverse-gamedev',
    icon: MetaverseSvgIcon.src,
    iconCmp: <MetaverseIcon />,
    developersCount: 135,
    clientReviewsCount: 91,
    rating: 4.9,
    type: 'domain',
    resumesIds: [
      '36aa0a2c-5509-4265-b999-e701b204ad18',
      'a7e2ccea-ab3a-4991-816c-8920fda04284',
      'cc5dc550-b46a-4951-a36e-1a12ef62d03a',
      '38ff0b97-c2ef-463c-a5ac-4c048cdc2308',
    ],
  },
  {
    publicName: 'Ed-tech',
    url: 'edtech',
    icon: EdTechSvgIcon.src,
    iconCmp: <EdTechIcon />,
    developersCount: 171,
    clientReviewsCount: 100,
    rating: 4.8,
    type: 'domain',
    resumesIds: [
      '36aa0a2c-5509-4265-b999-e701b204ad18',
      '9a4d6c21-9a47-476a-9e64-18360e557cde',
      'c722688d-d127-4db9-b1b8-6954f8b3193f',
      'e7643a57-8b4f-47e0-88d7-6fa37b842fcf',
    ],
  },
  {
    publicName: 'Mar-tech',
    url: 'martech',
    icon: AdTechSvgIcon.src,
    iconCmp: <AdTechIcon />,
    developersCount: 139,
    clientReviewsCount: 90,
    rating: 4.9,
    type: 'domain',
    resumesIds: [
      '53bf45b3-696a-4fec-88eb-63cd45efa3b2',
      'ea79e4d0-35d3-48ea-8586-e58ac3d2e91b',
      '4fc2b836-0a47-432a-acc4-a6e696f92cc9',
      '362a0c1b-509e-4929-85be-2d486a816728',
    ],
  },
  {
    publicName: 'AI/Big data',
    url: 'ai-bigdata',
    icon: AISvgIcon.src,
    iconCmp: <AIIcon />,
    developersCount: 149,
    clientReviewsCount: 69,
    rating: 4.8,
    type: 'domain',
    resumesIds: [
      '238517ab-d649-4546-b458-4dcfc44ce1dc',
      'cd04161f-7835-4be0-8809-8b801a289aa5',
      '365c6d36-5daa-4dff-adbc-2eaa9f96be35',
      '05cfe83e-5ff4-4888-958d-b5eb92a98ac9',
      '62009618-ba59-48d3-824c-377718611cc1',
    ],
  },
]

export const PROD_DATA_DOMAINS = _BASE_DATA_DOMAINS
export const QA_DATA_DOMAINS = _BASE_DATA_DOMAINS.map((domain) => ({
  ...domain,
  resumesIds: new Array(5).fill('902bd08b-b7f4-4e51-873e-f14e97d9fff3'),
}))

export const CURRENT_DATA_DOMAINS = isProd()
  ? PROD_DATA_DOMAINS
  : QA_DATA_DOMAINS
