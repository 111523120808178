import React from 'react'

export type LogoIconProps = React.HtmlHTMLAttributes<SVGElement>

export const LogoIcon: React.FC<LogoIconProps> = ({ ...svgProps }) => {
  return (
    <svg
      width="105"
      height="32"
      viewBox="0 0 105 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="M4.69128 4.63052V24.0549H0V4.63052H4.69128Z" fill="#1B1B1B" />
      <path
        d="M13.0918 24.0549H8.52393V9.25537H12.0733L12.5979 11.1053C13.2564 10.427 13.9971 9.89257 14.8201 9.50203C15.6432 9.11149 16.5382 8.91621 17.5053 8.91621C19.1719 8.91621 20.4476 9.36842 21.3324 10.2728C22.2171 11.1773 22.6595 12.503 22.6595 14.2502V24.0549H18.0917V14.7127C18.0917 13.9933 17.9168 13.428 17.567 13.0169C17.2378 12.5853 16.744 12.3694 16.0855 12.3694C15.5917 12.3694 15.067 12.5133 14.5115 12.8011C13.9765 13.0889 13.5033 13.4691 13.0918 13.9419V24.0549Z"
        fill="currentColor"
      />
      <path
        d="M35.3821 13.0477C34.8266 12.7805 34.2401 12.575 33.6229 12.4311C33.0056 12.2667 32.4089 12.1844 31.8328 12.1844C31.2978 12.1844 30.8657 12.2769 30.5365 12.4619C30.2073 12.6469 30.0427 12.9039 30.0427 13.2327C30.0427 13.5822 30.197 13.8802 30.5056 14.1269C30.8349 14.353 31.4727 14.5996 32.4192 14.8669L33.3451 15.1443C34.5796 15.5143 35.5158 16.059 36.1537 16.7785C36.7915 17.4773 37.1105 18.3817 37.1105 19.4917C37.1105 20.2317 36.9562 20.91 36.6475 21.5266C36.3389 22.1227 35.8965 22.6366 35.3204 23.0683C34.7648 23.4999 34.0858 23.8288 33.2834 24.0549C32.5015 24.281 31.627 24.3941 30.66 24.3941C29.6106 24.3941 28.6744 24.3016 27.8514 24.1166C27.0489 23.9316 26.2979 23.6644 25.5983 23.3149L26.5859 20.0159C27.1826 20.3242 27.7999 20.5811 28.4378 20.7867C29.0962 20.9717 29.7649 21.0642 30.4439 21.0642C31.2258 21.0642 31.771 20.9511 32.0797 20.725C32.4089 20.4989 32.5735 20.2317 32.5735 19.9234C32.5735 19.7384 32.5324 19.5842 32.4501 19.4609C32.3883 19.317 32.2649 19.1834 32.0797 19.0601C31.8945 18.9367 31.627 18.8237 31.2772 18.7209C30.948 18.5976 30.5159 18.4537 29.981 18.2893L29.0242 18.0118C27.8102 17.6212 26.9254 17.0868 26.3699 16.4085C25.8144 15.7096 25.5366 14.7744 25.5366 13.6027C25.5366 12.9244 25.6703 12.3078 25.9378 11.7528C26.2053 11.1773 26.5859 10.6839 27.0798 10.2728C27.5942 9.84118 28.2114 9.51231 28.9316 9.2862C29.6723 9.03954 30.5159 8.91621 31.4624 8.91621C32.4089 8.91621 33.2834 9.00871 34.0858 9.19371C34.9089 9.35814 35.6496 9.59453 36.308 9.90285L35.3821 13.0477Z"
        fill="currentColor"
      />
      <path
        d="M49.4046 23.4074C49.0342 23.7157 48.5301 23.9521 47.8923 24.1166C47.2544 24.3016 46.6371 24.3941 46.0405 24.3941C44.9499 24.3941 43.9829 24.2091 43.1393 23.8391C42.2957 23.4485 41.5755 22.9141 40.9788 22.2358C40.4027 21.5575 39.9603 20.7456 39.6517 19.8C39.343 18.8545 39.1887 17.8062 39.1887 16.6551C39.1887 15.5452 39.343 14.5174 39.6517 13.5719C39.9603 12.6264 40.413 11.8145 41.0097 11.1361C41.6064 10.4373 42.3265 9.89257 43.1701 9.50203C44.0343 9.11149 45.0117 8.91621 46.1022 8.91621C46.8635 8.91621 47.6145 9.04982 48.3552 9.31703C49.096 9.56369 49.7235 9.94396 50.2379 10.4578L50.4848 9.25537H53.9724V31.1463H49.4046V23.4074ZM49.4046 13.4177C49.1782 13.13 48.8593 12.8628 48.4478 12.6161C48.0569 12.3489 47.5631 12.2153 46.9664 12.2153C46.5343 12.2153 46.1228 12.2975 45.7318 12.4619C45.3614 12.6264 45.0219 12.8833 44.7133 13.2327C44.4252 13.5822 44.1989 14.0344 44.0343 14.5894C43.8697 15.1238 43.7874 15.7816 43.7874 16.5626C43.7874 18.0631 44.1063 19.1937 44.7442 19.9542C45.4026 20.6942 46.1845 21.0642 47.0898 21.0642C47.6248 21.0642 48.0877 20.9717 48.4787 20.7867C48.8696 20.5811 49.1782 20.3447 49.4046 20.0775V13.4177Z"
        fill="currentColor"
      />
      <path
        d="M67.036 9.25537H71.6038V24.0549H68.2396L67.7767 22.4516H67.6532C67.4475 22.6572 67.2006 22.873 66.9125 23.0991C66.6244 23.3252 66.2747 23.541 65.8631 23.7466C65.4722 23.9316 65.0195 24.0857 64.5051 24.2091C63.9907 24.3324 63.4043 24.3941 62.7459 24.3941C61.141 24.3941 59.8859 23.9521 58.9805 23.0683C58.0752 22.1844 57.6225 20.8792 57.6225 19.1526V9.25537H62.1904V18.7826C62.1904 19.5431 62.3755 20.1084 62.7459 20.4783C63.1368 20.8278 63.6307 21.0025 64.2274 21.0025C64.9475 21.0025 65.5339 20.8586 65.9866 20.5708C66.4598 20.2625 66.8096 19.9439 67.036 19.615V9.25537Z"
        fill="currentColor"
      />
      <path
        d="M75.4721 10.5503C76.3157 10.057 77.2622 9.66647 78.3116 9.3787C79.3815 9.07038 80.472 8.91621 81.5831 8.91621C83.4144 8.91621 84.8856 9.35814 85.9967 10.242C87.1077 11.1259 87.6633 12.5339 87.6633 14.466V24.0549H84.1757L83.7745 22.5749H83.651C83.2395 23.1094 82.6634 23.5513 81.9226 23.9007C81.2025 24.2296 80.3486 24.3941 79.3609 24.3941C78.5173 24.3941 77.7766 24.2707 77.1388 24.0241C76.5215 23.7774 76.0071 23.4485 75.5956 23.0374C75.1841 22.6263 74.8651 22.1433 74.6388 21.5883C74.433 21.0128 74.3302 20.4064 74.3302 19.7692C74.3302 18.9059 74.5154 18.1659 74.8857 17.5493C75.2766 16.9326 75.791 16.429 76.4289 16.0385C77.0873 15.6479 77.8486 15.3602 78.7128 15.1752C79.5976 14.9902 80.5338 14.8977 81.5214 14.8977H83.0646V14.096C83.0646 13.5411 82.8691 13.1197 82.4782 12.8319C82.0872 12.5236 81.5317 12.3694 80.8115 12.3694C80.1943 12.3694 79.5873 12.4619 78.9906 12.6469C78.3939 12.8319 77.7355 13.13 77.0153 13.5411L75.4721 10.5503ZM83.0955 17.6726L82.077 17.7034C81.5008 17.724 81.0173 17.7857 80.6264 17.8884C80.2354 17.9912 79.9165 18.1248 79.6696 18.2893C79.4433 18.4331 79.2786 18.6181 79.1758 18.8442C79.0729 19.0498 79.0214 19.2656 79.0214 19.4917C79.0214 20.1289 79.2066 20.5708 79.577 20.8175C79.9679 21.0436 80.4103 21.1567 80.9041 21.1567C81.398 21.1567 81.8403 21.0128 82.2313 20.725C82.6428 20.4372 82.9308 20.1186 83.0955 19.7692V17.6726Z"
        fill="currentColor"
      />
      <path
        d="M100.432 2.00977H105V24.0549H101.852L101.389 22.4516H101.235C100.947 22.9038 100.442 23.3458 99.7223 23.7774C99.0227 24.1885 98.0968 24.3941 96.9446 24.3941C95.9775 24.3941 95.0825 24.2193 94.2594 23.8699C93.4364 23.4999 92.7265 22.986 92.1298 22.3283C91.5331 21.65 91.0599 20.8381 90.7101 19.8925C90.3809 18.9265 90.2163 17.8473 90.2163 16.6551C90.2163 15.4835 90.3706 14.4249 90.6792 13.4794C90.9879 12.5133 91.4303 11.7014 92.0064 11.0436C92.6031 10.3653 93.3335 9.84118 94.1977 9.4712C95.0619 9.10121 96.0392 8.91621 97.1298 8.91621C97.8088 8.91621 98.4466 9.01899 99.0433 9.22454C99.6606 9.40953 100.124 9.64591 100.432 9.93368V2.00977ZM100.432 13.2636C100.247 13.0169 99.9589 12.7805 99.568 12.5544C99.177 12.3283 98.745 12.2153 98.2717 12.2153C97.7985 12.2153 97.3561 12.2975 96.9446 12.4619C96.5331 12.6058 96.1627 12.8525 95.8335 13.2019C95.5248 13.5513 95.2779 14.0036 95.0928 14.5585C94.9076 15.093 94.815 15.761 94.815 16.5626C94.815 18.1043 95.1133 19.2451 95.71 19.985C96.3273 20.7045 97.1195 21.0642 98.0865 21.0642C98.6832 21.0642 99.177 20.91 99.568 20.6017C99.9795 20.2934 100.268 19.9645 100.432 19.615V13.2636Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69128 4.32227V24.3633H0V4.32227H4.69128ZM13.0918 24.3633H8.52393V9.09394H12.0733L12.5979 11.0026C13.2564 10.3028 13.9971 9.75137 14.8201 9.34843C15.6432 8.94549 16.5382 8.74402 17.5053 8.74402C19.1719 8.74402 20.4476 9.21058 21.3324 10.1437C22.2171 11.0768 22.6595 12.4447 22.6595 14.2473V24.3633H18.0917V14.7245C18.0917 13.9823 17.9168 13.399 17.567 12.9749C17.2378 12.5295 16.744 12.3069 16.0855 12.3069C15.5917 12.3069 15.067 12.4553 14.5115 12.7522C13.9765 13.0491 13.5033 13.4415 13.0918 13.9292V24.3633Z"
        fill="#373AF5"
      />
    </svg>
  )
}
