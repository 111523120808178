import React from 'react'

import clsx from 'clsx'

import s from './Switchable.module.scss'

export interface ISwitchableProps {
  children: React.ReactNode

  isOpen?: boolean
  className?: string
  maxHeight?: number | string
}

export const Switchable: React.FC<ISwitchableProps> = ({
  isOpen,
  className,
  children,
  maxHeight,
}) => {
  const refCallback = (node: HTMLDivElement | null) => {
    if (node) {
      if (isOpen) {
        node.style.maxHeight =
          typeof maxHeight === 'string'
            ? maxHeight
            : (maxHeight || node.scrollHeight || 0) + 'px'
      } else {
        node.style.maxHeight = '0px'
      }
    }
  }

  return (
    <div className={clsx(s.Switchable, className)} ref={refCallback}>
      {children}
    </div>
  )
}
