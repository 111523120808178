import React from 'react'

import {
  MenuButtonItem,
  MobileMenuSubItem,
} from './MenuButtonItem/MenuButtonItem'
import { MenuUsualItem } from './MenuUsualItem/MenuUsualItem'

import s from './MobileMenuItems.module.scss'

export type MobileMenuItem = MenuUsualItem | MenuButtonItem

export type MobileMenuItemsProps = {
  items: Array<MobileMenuItem>
  selectedItemIndex: number | null

  onSelectedItem: (index: number | null) => void
  onSubItem: (type: MobileMenuSubItem) => void
}

export const MobileMenuItems: React.FC<MobileMenuItemsProps> = ({
  items,
  selectedItemIndex,
  onSelectedItem,
  onSubItem,
}) => {
  const renderItem = (item: MobileMenuItem, index: number) => {
    if ('href' in item) {
      return <MenuUsualItem item={item} />
    }

    const isSelected = index === selectedItemIndex

    return (
      <MenuButtonItem
        item={item}
        isSelected={isSelected}
        onClick={() => onSelectedItem(isSelected ? null : index)}
        onSubItem={onSubItem}
      />
    )
  }

  return (
    <ul className={s.MobileMenuItems}>
      {items.map((item, i) => (
        <React.Fragment key={i}>{renderItem(item, i)}</React.Fragment>
      ))}
    </ul>
  )
}
