import React, { useRef } from 'react'
import { animated } from 'react-spring'

import clsx from 'clsx'

import {
  useIntersectionObserver,
  UseIntersectionObserverProps,
} from 'src/common/hooks/useIntersectionObserver'

import {
  useCardsHopAnimation,
  UseCardsHopAnimationProps,
} from './useCardsHopAnimation'

// NOTICE Prop Component can realize transition: transform ... property to get transition
export type CardsHopAnimationProps<T> = {
  cards: Array<T>
  animationConfig?: Omit<UseCardsHopAnimationProps<T>, 'cards' | 'isPaused'>
  isImmediate?: boolean
  intersectionObserverProps?: UseIntersectionObserverProps
  wrapperClassName?: string
  renderCard: (item: T, index: number, isPaused: boolean) => React.ReactNode
}

export const CardsHopAnimation = <T,>({
  cards,
  animationConfig,
  isImmediate,
  intersectionObserverProps,
  wrapperClassName,
  renderCard,
}: CardsHopAnimationProps<T>) => {
  const firstCardRef = useRef<any>(null)
  const observerEntry = useIntersectionObserver(firstCardRef, {
    ...intersectionObserverProps,
    threshold: intersectionObserverProps?.threshold || 0.35,
    freezeOnceVisible: true,
  })

  const isPaused = !isImmediate ? !observerEntry?.isIntersecting : false
  const springs = useCardsHopAnimation({
    cards,
    isPaused,
    ...animationConfig,
  })

  return (
    <>
      {springs.map((style, i) => {
        const card = cards[i]

        return (
          <animated.div
            ref={i === 0 ? firstCardRef : undefined}
            style={style}
            key={i}
            className={clsx(wrapperClassName)}
          >
            {renderCard(card, i, isPaused)}
          </animated.div>
        )
      })}
    </>
  )
}
