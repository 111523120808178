import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const AIIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="60"
      height="32"
      viewBox="0 0 60 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="8" stroke="currentColor" />
      <circle cx="23.5" cy="25.5" r="4" stroke="currentColor" />
      <circle cx="25.5" cy="5.5" r="2" stroke="currentColor" />
      <circle cx="44.5" cy="11.5" r="8" stroke="currentColor" />
      <circle cx="53.5" cy="26.5" r="2" stroke="currentColor" />
      <path d="M16 17L21 22" stroke="currentColor" strokeLinecap="round" />
      <path d="M18 7.5L23.5 6" stroke="currentColor" strokeLinecap="round" />
      <path d="M28 6L36.5 9.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M25.5 7.5L24 21.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M27.5 26.5H51.5" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M49 18.5L52.5 24.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path d="M27 23L38 16.5" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
