import Airtable from 'airtable'

import { captureException } from '@sentry/nextjs'
import { AirtableBase } from 'airtable/lib/airtable_base'

import { APP_AIRTABLE_COMPANY_BASE_ID } from './config'

export type AirtableUtilsBases = {
  companyBase: AirtableBase
}

export class AirtableUtils {
  private static _bases: AirtableUtilsBases | null = null

  private static _initBases() {
    this._bases = {
      companyBase: this.getBase(APP_AIRTABLE_COMPANY_BASE_ID),
    }
  }

  static getBase(baseId: string) {
    const airtableInstance = new Airtable()
    return airtableInstance.base(baseId)
  }

  static makeOperation(cb: (bases: AirtableUtilsBases) => void) {
    if (!this._bases) {
      this._initBases()
    }

    try {
      cb(this._bases!)
    } catch (err) {
      captureException(err)
    }
  }
}
