import React, { useState } from 'react'
import { useTransition, animated, config, SpringValue } from 'react-spring'

import clsx from 'clsx'

import { CURRENT_DATA_DOMAINS } from 'src/data/domainData/domains'
import { CURRENT_DATA_SKILLS } from 'src/data/skillsData/skills'

import { TooltipMenuDomains } from './TooltipMenuDomains/TooltipMenuDomains'
import { TooltipMenuHeading } from './TooltipMenuHeading/TooltipMenuHeading'
import { TooltipMenuSkills } from './TooltipMenuSkills/TooltipMenuSkills'

import s from './TooltipMenu.module.scss'

enum TooltipMenuActiveTypesEnum {
  DOMAIN_TYPE,
  SKILL_TYPE,
}

export type TooltipMenuProps = {
  isVisible: boolean

  setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

export const TooltipMenu: React.FC<TooltipMenuProps> = ({
  isVisible,
  setTooltipRef,
}) => {
  const [activeType, setActiveType] = useState(
    TooltipMenuActiveTypesEnum.DOMAIN_TYPE
  )

  const handleHeadingClick = (newType: TooltipMenuActiveTypesEnum) => {
    return () => setActiveType(newType)
  }

  const isDomainTypeActive =
    activeType === TooltipMenuActiveTypesEnum.DOMAIN_TYPE
  const isSkillTypeActive = activeType === TooltipMenuActiveTypesEnum.SKILL_TYPE

  const tooltipTransition = useTransition(isVisible, {
    from: { opacity: 0, pointerEvents: 'none', transform: 'translateY(-10px)' },
    enter: { opacity: 1, pointerEvents: 'auto', transform: 'translateY(0px)' },
    leave: {
      opacity: 0,
      pointerEvents: 'none',
      transform: 'translateY(-10px)',
    },
    config: config.stiff,
  })

  const renderContent = () => {
    if (isDomainTypeActive) {
      return <TooltipMenuDomains domains={CURRENT_DATA_DOMAINS} />
    }

    return <TooltipMenuSkills skills={CURRENT_DATA_SKILLS} />
  }

  return tooltipTransition(
    (style, isVisible) =>
      isVisible && (
        <animated.div
          style={style as unknown as Record<string, SpringValue<string>>}
          ref={setTooltipRef}
          className={s.TooltipMenu}
        >
          <div className={clsx(s.TooltipMenu__inner, 'container')}>
            <div className={s.TooltipMenu__block}>
              <TooltipMenuHeading
                text="Find developers in your domain"
                className={s.TooltipMenu__heading}
                isActive={isDomainTypeActive}
                onClick={handleHeadingClick(
                  TooltipMenuActiveTypesEnum.DOMAIN_TYPE
                )}
              />
              <TooltipMenuHeading
                text="Find developers by skill"
                className={s.TooltipMenu__heading}
                isActive={isSkillTypeActive}
                onClick={handleHeadingClick(
                  TooltipMenuActiveTypesEnum.SKILL_TYPE
                )}
              />
            </div>

            <div className={s.TooltipMenu__block}>{renderContent()}</div>
          </div>
        </animated.div>
      )
  )
}
