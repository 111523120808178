import React, { useCallback } from 'react'

import clsx from 'clsx'

import { useEventListener, useToggle } from '@insquad/tools'

import { Logo } from '../Logo/Logo'

import { HeaderActions } from './HeaderActions/HeaderActions'
import { HeaderMenu } from './HeaderMenu/HeaderMenu'
import { HeaderMobileMenu } from './HeaderMobileMenu/HeaderMobileMenu'

import s from './Header.module.scss'

export type HeaderProps = {
  pageAnalytic: string
}

export const Header: React.FC<HeaderProps> = ({ pageAnalytic }) => {
  const animationToggler = useToggle()

  const handleScroll = useCallback(() => {
    if (window.scrollY > 10) {
      animationToggler.set()
    }

    if (window.scrollY <= 10 && animationToggler.value) {
      animationToggler.unset()
    }
  }, [animationToggler])

  useEventListener('scroll', handleScroll)

  return (
    <div className={s.Header__outer}>
      <header
        className={clsx(s.Header, {
          [s.Header_animated]: animationToggler.value,
        })}
      >
        <div className={s.Header__relative}>
          <div className={clsx(s.Header__inner, 'container')}>
            <Logo className={s.Header__logo} />

            <HeaderMobileMenu className={s.Header__mobileMenu} />
            <HeaderMenu className={s.Header__menu} />
            <HeaderActions pageAnalytic={pageAnalytic} />
          </div>
        </div>
      </header>
    </div>
  )
}
