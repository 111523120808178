import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import { EnumTypographyVariants, Typography } from '../../Typography/Typography'

import s from './FooterBottom.module.scss'

export const FooterBottom: React.FC<UnknownObject> = () => {
  return (
    <div className={s.FooterBottom}>
      <Typography text="Copyright" variant={EnumTypographyVariants.H9} />
      <Typography
        text="256 CHAPMAN ROAD STE 105-4, NEWARK"
        variant={EnumTypographyVariants.H9}
        className={s.FooterBottom__address}
        as="address"
      />
    </div>
  )
}
