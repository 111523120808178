import { AnchorIdsEnum } from 'src/common/enums/anchorIdsEnum'
import { RoutesEnum } from 'src/common/enums/routesEnum'

import { FooterMiddleListProps } from './FooterMiddleList/FooterMiddleList'

export const FOOTER_MIDDLE_LISTS: Array<
  Omit<FooterMiddleListProps, 'className'>
> = [
  {
    title: 'For clients',
    links: [
      // {
      //   text: 'Screening process',
      //   href: '/',
      // },
      {
        text: 'Find developer by domain',
        href: `#${AnchorIdsEnum.DOMAIN_BLOCK_ANCHOR}`,
      },
      {
        text: 'Find developer by skills',
        href: `#${AnchorIdsEnum.BROWSE_BLOCK_ANCHOR}`,
      },
      {
        text: 'How to hire',
        href: `#${AnchorIdsEnum.FAQ_BLOCK_ANCHOR}`,
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        text: 'Reviews & Cases',
        internalLink: RoutesEnum.STORIES_PAGE,
      },
      {
        text: 'Blog',
        internalLink: RoutesEnum.BLOG_PAGE,
      },
      {
        text: 'Insquad in media',
        href: `#${AnchorIdsEnum.MEDIA_BLOCK_ANCHOR}`,
      },
    ],
  },
  // {
  //   title: 'Company',
  //   links: [
  //     {
  //       text: 'About us',
  //       href: '/',
  //     },
  //   ],
  // },
  {
    title: 'Legal',
    links: [
      {
        text: 'Terms of service',
        href: 'https://insquad-docs.s3.amazonaws.com/Terms-of-use.pdf',
        anchorProps: {
          target: '_blank',
          rel: 'nofollow noreferrer',
        },
      },
      {
        text: 'Privacy Policy',
        href: 'https://insquad-docs.s3.amazonaws.com/Privacy-policy.pdf',
        anchorProps: {
          target: '_blank',
          rel: 'nofollow noreferrer',
        },
      },
    ],
  },
]
