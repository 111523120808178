import amplitude from 'amplitude-js'

import { AnalyticEventEnum } from './analyticEventEnum'
import { IAnalytic } from './types'

export class Analytic implements IAnalytic {
  constructor(key: string) {
    amplitude.getInstance().init(key, undefined, {
      deviceIdFromUrlParam: true,
      saveEvents: true,
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
    })

    amplitude.getInstance().logEvent('session_start')
  }

  event(
    event: AnalyticEventEnum,
    properties?: Record<string, string | string[]>
  ) {
    amplitude.getInstance().logEvent(event, properties)
  }
}
