import React from 'react'

import clsx from 'clsx'

import Link from 'next/link'

import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { DomainType } from 'src/data/domainData/domains'

import s from './DomainCard.module.scss'

export type DomainCardProps = {
  className?: string
} & DomainType

export const DomainCard: React.FC<DomainCardProps> = ({
  url,
  publicName,
  className,
  iconCmp,
}) => {
  return (
    <Link href={`/${url}`}>
      <a href={url} className={clsx(s.DomainCard, className)}>
        <div className={s.DomainCard__icon}>{iconCmp}</div>

        <div className={s.DomainCard__title}>
          <Typography variant={EnumTypographyVariants.H7}>
            {publicName}
          </Typography>

          <div className={s.DomainCard__arrow}>
            <ArrowIcon />
          </div>
        </div>
      </a>
    </Link>
  )
}
