import React from 'react'

import { CardsHopAnimation } from 'src/common/animations/CardsHopAnimation/CardsHopAnimation'
import { SkillType } from 'src/data/skillsData/skills'

import { SkillCard } from '../../../_common/SkillCard/SkillCard'

import s from './TooltipMenuSkills.module.scss'

export type TooltipMenuSkillsProps = {
  skills: Array<SkillType>
}

export const TooltipMenuSkills: React.FC<TooltipMenuSkillsProps> = ({
  skills,
}) => {
  return (
    <div className={s.TooltipMenuSkills}>
      <CardsHopAnimation
        cards={skills.slice(0, 9)}
        renderCard={(skill) => <SkillCard {...skill} />}
        animationConfig={{ delayMultiplier: 100 }}
      />
    </div>
  )
}
