import React from 'react'

import { FacebookSocialIcon } from 'src/common/components/icons/social/FacebookSocialIcon'
import { InstagramSocialIcon } from 'src/common/components/icons/social/InstagramSocialIcon'
import { LinkedInSocialIcon } from 'src/common/components/icons/social/LinkedInSocialIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { FooterMiddleEmailIcon } from './FooterMiddleEmailIcon'

import s from './FooterMiddleContacts.module.scss'

const SOCIAL_ICONS = [
  {
    icon: <FacebookSocialIcon />,
    href: 'https://www.facebook.com/insquad.dev/',
  },
  {
    icon: <LinkedInSocialIcon />,
    href: 'https://www.linkedin.com/company/insquadcom/mycompany/',
  },
  {
    icon: <InstagramSocialIcon />,
    href: 'https://www.instagram.com/insquadcom/',
  },
]

export const FooterMiddleContacts: React.FC<UnknownObject> = () => {
  return (
    <div className={s.FooterMiddleContacts}>
      <div className={s.FooterMiddleContacts__contact}>
        <a
          href="mailto:partners@insquad.com"
          className={s.FooterMiddleContacts__emailLink}
        >
          <Typography
            variant={EnumTypographyVariants.H9}
            className={s.FooterMiddleContacts__title}
            text="Contact us"
          />

          <FooterMiddleEmailIcon />
        </a>
      </div>

      <div className={s.FooterMiddleContacts__contact}>
        <Typography
          variant={EnumTypographyVariants.H9}
          className={s.FooterMiddleContacts__title}
          text="Follow us"
        />

        <ul className={s.FooterMiddleContacts__social}>
          {SOCIAL_ICONS.map(({ icon, href }, i) => (
            <li key={i} className={s.FooterMiddleContacts__socialIcon}>
              <a
                href={href}
                target="_blank"
                rel="nofollow noreferrer"
                className={s.FooterMiddleContacts__socialIconLink}
              >
                {icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
