import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const Web3Icon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="60"
      height="32"
      viewBox="0 0 60 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.70577 6.78867L9 2.57735L16.2942 6.78867V15.2113L9 19.4227L1.70577 15.2113V6.78867Z"
        stroke="currentColor"
      />
      <path d="M2 7L9 11M9 11L16 7M9 11V19.5" stroke="currentColor" />
      <path d="M16 18L23 22M23 22L30 18M23 22V30.5" stroke="currentColor" />
      <path
        d="M38.7058 6.78867L46 2.57735L53.2942 6.78867V15.2113L46 19.4227L38.7058 15.2113V6.78867Z"
        stroke="currentColor"
      />
      <path d="M39 7L46 11M46 11L53 7M46 11V19.5" stroke="currentColor" />
      <path
        d="M12.7684 12.5782C12.5355 12.4299 12.2264 12.4986 12.0782 12.7316C11.9299 12.9645 11.9986 13.2736 12.2316 13.4218L12.7684 12.5782ZM17.7316 16.9218C17.9645 17.0701 18.2736 17.0014 18.4218 16.7684C18.5701 16.5355 18.5014 16.2264 18.2684 16.0782L17.7316 16.9218ZM12.2316 13.4218L17.7316 16.9218L18.2684 16.0782L12.7684 12.5782L12.2316 13.4218Z"
        fill="currentColor"
      />
      <path d="M42.5 13L30.5 20" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M15.7058 17.7887L23 13.5774L30.2942 17.7887V26.2113L23 30.4227L15.7058 26.2113V17.7887Z"
        stroke="currentColor"
      />
    </svg>
  )
}
