import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { mergeRefs } from 'src/utils/mergeRefs'

import {
  EnumTextButtonVariants,
  ITextButtonProps,
  TextButtonDefaultAsType,
} from './types'

import s from './TextButton.module.scss'

const TextButtonInner = <E extends React.ElementType = TextButtonDefaultAsType>(
  {
    as,
    children,
    className,
    classes,
    iconLeft,
    iconRight,
    innerRef,
    disabled,
    isLoading,
    variant = EnumTextButtonVariants.PRIMARY,
    contentVariant = EnumTypographyVariants.H7,
    ...otherProps
  }: ITextButtonProps<E>,
  ref: React.ForwardedRef<E>
) => {
  const Component = as || TextButtonDefaultAsType

  const _disabled = isLoading || disabled

  return (
    <Component
      ref={mergeRefs([innerRef, ref])}
      className={clsx(
        s.TextButton,
        s[`TextButton_${variant}`],
        { [s.TextButton_disabled]: _disabled },
        classes?.root,
        className
      )}
      disabled={_disabled}
      {...otherProps}
    >
      {iconLeft && !isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconLeft,
            classes?.iconLeft
          )}
        >
          {iconLeft}
        </div>
      )}

      <Typography
        className={clsx(classes?.content)}
        variant={contentVariant}
        as="div"
      >
        {children}
      </Typography>

      {iconRight && !isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconRight,
            classes?.iconRight
          )}
        >
          {iconRight}
        </div>
      )}

      {/* {isLoading && (
        <div
          className={clsx(
            s.TextButton__icon,
            s.TextButton__iconRight,
            classes?.iconRight
          )}
        >
          <Spinner size="sm" />
        </div>
      )} */}
    </Component>
  )
}

export const TextButton = React.forwardRef(TextButtonInner) as <
  E extends React.ElementType = 'div'
>(
  props: ITextButtonProps<E> & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof TextButtonInner>
