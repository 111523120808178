import React from 'react'

import clsx from 'clsx'

import { ChevronIcon } from 'src/common/components/icons/ChevronIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './TooltipMenuHeading.module.scss'

export type TooltipMenuHeadingProps = {
  className?: string
  isActive: boolean
  text: string

  onClick: () => void
}

export const TooltipMenuHeading: React.FC<TooltipMenuHeadingProps> = ({
  className,
  isActive,
  text,
  onClick,
}) => {
  return (
    <Typography
      className={clsx(
        s.TooltipMenuHeading,
        { [s.TooltipMenuHeading_active]: isActive },
        className
      )}
      variant={EnumTypographyVariants.H7}
      onClick={onClick}
    >
      <div className={s.TooltipMenuHeading__text}>{text}</div>

      <div className={s.TooltipMenuHeading__icon}>
        <ChevronIcon />
      </div>
    </Typography>
  )
}
