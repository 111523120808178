import React from 'react'

import clsx from 'clsx'

// import { ReactComponent as AlertTriangleIcon } from 'assets/icons/simple/alertTriangleIcon.svg'
import { IntrinsicPropsWithoutRef } from 'src/utils/reactTypes'

import s from './FieldContainer.module.scss'

export interface IFieldContainerProps extends IntrinsicPropsWithoutRef<'div'> {
  label?: string
  helpText?: string
  error?: boolean
  marginBottom?: boolean
  children?: React.ReactNode
  htmlFor?: string

  labelProps?: Omit<IntrinsicPropsWithoutRef<'label'>, 'htmlFor'>
}

export const FieldContainer = React.forwardRef<
  HTMLDivElement,
  IFieldContainerProps
>(
  (
    {
      label,
      children,
      helpText,
      error,
      marginBottom,
      className,
      labelProps,
      htmlFor,
      ...containerProps
    },
    innerRef
  ) => {
    return (
      <div
        {...containerProps}
        className={clsx(
          { [s.FieldContainer__withMargin]: marginBottom },
          className
        )}
        ref={innerRef}
      >
        {label && (
          <label
            {...labelProps}
            htmlFor={htmlFor}
            className={clsx(s.FieldContainer__label, labelProps?.className)}
          >
            {label}
          </label>
        )}

        {children}
      </div>
    )
  }
)

FieldContainer.displayName = 'FieldContainer'
