import React from 'react'

import clsx from 'clsx'

import s from './BurgerMenu.module.scss'

export interface IBurgerMenuClasses {
  openClass?: string
  closeClass?: string

  btnOpenClass?: string
  btnCloseClass?: string
  btnClass?: string
}

export interface IBurgerMenuProps {
  className?: string
  classes?: IBurgerMenuClasses
  isOpen?: boolean
  onClick?: (e: React.MouseEvent) => void
}

export const BurgerMenu: React.FC<IBurgerMenuProps> = ({
  className,
  isOpen,
  onClick,
  classes,
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        s.BurgerMenu,
        {
          [s.BurgerMenu_opened]: isOpen,
          [classes?.closeClass || '']: !isOpen,
          [classes?.openClass || '']: isOpen,
        },
        className
      )}
      tabIndex={1}
    >
      <div
        className={clsx(s.BurgerMenu__btn, classes?.btnClass, {
          [classes?.btnCloseClass || '']: !isOpen,
          [classes?.btnOpenClass || '']: isOpen,
        })}
      />
    </button>
  )
}
