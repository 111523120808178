import React from 'react'
import ReactDOM from 'react-dom'
import { useTransition, config, animated } from 'react-spring'

import s from './MobileMenuBlackout.module.scss'

export type MobileMenuBlackoutProps = {
  isVisible: boolean
  onClick?: () => void
}

export const MobileMenuBlackout: React.FC<MobileMenuBlackoutProps> = ({
  isVisible,
  onClick,
}) => {
  const transition = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
  })

  if (typeof window === 'undefined') {
    return null
  }

  return ReactDOM.createPortal(
    <>
      {transition(
        (style, isVisible) =>
          isVisible && (
            <animated.div
              className={s.MobileMenuBlackout}
              onClick={onClick}
              style={style}
            />
          )
      )}
    </>,
    document.body
  )
}
