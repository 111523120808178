import React from 'react'
import { useTransition, animated } from 'react-spring'

import s from './MobileMenuDrawer.module.scss'

export type MobileMenuDrawerProps = {
  isVisible: boolean
  children: React.ReactNode
}

export const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({
  isVisible,
  children,
}) => {
  const transition = useTransition(isVisible, {
    from: { opacity: 0, transform: 'translateX(-100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
  })

  return transition(
    (style, isVisible) =>
      isVisible && (
        <animated.aside style={style} className={s.MobileMenuDrawer}>
          {children}
        </animated.aside>
      )
  )
}
