import React, { useRef } from 'react'

import clsx from 'clsx'

import { mergeRefs } from 'src/utils/mergeRefs'

import {
  FieldContainer,
  IFieldContainerProps,
} from '../_FieldContainer/FieldContainer'

import s from './Input.module.scss'

export interface IInputProps
  extends Omit<
    React.HtmlHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  containerProps?: IFieldContainerProps
  className?: string
  error?: boolean
  disabled?: boolean
  value?: string
  autoFocus?: boolean
  type?: string
  startIcon?: JSX.Element | null
  endIcon?: JSX.Element | null

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      error,
      disabled,
      containerProps,
      autoFocus,
      startIcon,
      endIcon,
      ...otherProps
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const handleClickIcon = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    const _error = containerProps?.error || error

    return (
      <FieldContainer {...containerProps}>
        <div className={s.Input__wrapper}>
          {startIcon && (
            <div onClick={handleClickIcon} className={s.Input__icon_start}>
              {startIcon}
            </div>
          )}

          <input
            {...otherProps}
            ref={mergeRefs([ref, inputRef])}
            disabled={disabled}
            autoFocus={autoFocus}
            className={clsx(
              s.Input,
              {
                [s.Input_error]: _error,
                [s.Input_disabled]: disabled,
                [s.Input_startIcon]: !!startIcon,
                [s.Input_endIcon]: !!endIcon,
              },
              className
            )}
          />

          {endIcon && (
            <div onClick={handleClickIcon} className={s.Input__icon_end}>
              {endIcon}
            </div>
          )}
        </div>
      </FieldContainer>
    )
  }
)

Input.displayName = 'Input'
