import React from 'react'

import { clsx } from 'clsx'

import { Button } from '@insquad/tools'

import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import { EnumTextButtonVariants, TextButton } from '../../TextButton'
import { EnumTypographyVariants } from '../../Typography/Typography'

import s from './HeaderActions.module.scss'

export type HeaderActionsProps = {
  pageAnalytic: string
}

export const HeaderActions: React.FC<HeaderActionsProps> = ({
  pageAnalytic,
}) => {
  const { analytic } = useAnalytic()
  const { handleSigInRedirect, handleSignUpRedirect } = useAuthRedirect()

  const handleSignUp = () => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      page: pageAnalytic,
      from: 'header',
    })
    handleSignUpRedirect()
  }

  return (
    <>
      <div className={clsx(s.HeaderActions, s.HeaderActions_desktop)}>
        <TextButton
          id="button-header-login"
          variant={EnumTextButtonVariants.SECONDARY}
          contentVariant={EnumTypographyVariants.H7}
          className={s.HeaderActions__action}
          tabIndex={1}
          onClick={() => handleSigInRedirect()}
        >
          Log In
        </TextButton>

        <Button
          id="button-header-signup"
          onClick={handleSignUp}
          className={clsx(s.HeaderActions__action, s.HeaderActions__btn)}
          tabIndex={1}
        >
          Sign Up
        </Button>
      </div>

      <div className={clsx(s.HeaderActions, s.HeaderActions_mobile)}>
        <TextButton
          id="button-header-signup"
          onClick={handleSignUp}
          variant={EnumTextButtonVariants.SECONDARY}
          tabIndex={1}
        >
          Sign Up
        </TextButton>
      </div>
    </>
  )
}
