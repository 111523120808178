import React from 'react'

import clsx from 'clsx'

import { ChevronIcon } from 'src/common/components/icons/ChevronIcon'
import { Switchable } from 'src/common/components/Switchable/Switchable'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './MenuButtonItem.module.scss'

export enum MobileMenuSubItemEnum {
  FIND_DOMAIN,
  FIND_SKILL,
}

export type MobileMenuSubItem = {
  text: string
  type: MobileMenuSubItemEnum
}

export type MenuButtonItem = {
  text: string
  subItems: Array<MobileMenuSubItem>
}

export type MenuButtonItemProps = {
  item: MenuButtonItem
  isSelected: boolean
  onClick: () => void
  onSubItem: (type: MobileMenuSubItem) => void
}

export const MenuButtonItem: React.FC<MenuButtonItemProps> = ({
  item,
  isSelected,
  onClick,
  onSubItem,
}) => {
  const { subItems, text } = item

  const handleSubItem = (item: MobileMenuSubItem) => {
    return () => onSubItem(item)
  }

  return (
    <li
      className={clsx(s.MenuButtonItem, {
        [s.MenuButtonItem_active]: isSelected,
      })}
    >
      <div onClick={onClick} className={s.MenuButtonItem__top}>
        <button>{text}</button>

        <div className={s.MenuButtonItem_icon}>
          <ChevronIcon />
        </div>
      </div>

      <Switchable isOpen={isSelected}>
        <ul className={s.MenuButtonItem__subList}>
          {subItems.map((subItem, i) => (
            <Typography
              key={i}
              variant={EnumTypographyVariants.H7}
              className={s.MenuButtonItem__subListItem}
              as="li"
              onClick={handleSubItem(subItem)}
            >
              {subItem.text}

              <div className={s.MenuButtonItem__chevron}>
                <ChevronIcon />
              </div>
            </Typography>
          ))}
        </ul>
      </Switchable>
    </li>
  )
}
